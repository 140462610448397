import React, { Fragment, useContext, useState } from 'react'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import "./SideMenu.css"
import AuthContext from '../../../store/auth-context';
import { useHistory } from 'react-router-dom'
import AboutModal from '../AmoutModal/AboutModal';
import HorizontalDivider from '../../Divider/HorizontalDivider';


const SideMenu = props => {
    const history = useHistory();
    const authCtx = useContext(AuthContext);
    const [showAboutModal, setShowAboutModal] = useState(false);

    const onLogoutHandler = (event) => {
        history.push("/")
        authCtx.onLogout();
    }

    const updateApp = () => {
        if (caches != null) {
            caches.keys().then(function (names) {
                for (let name of names) {
                    caches.delete(name);
                }
            });
        }
        window.location.reload();
        goToLogin()
    }

    const goToLogin = () => {
        history.push("/")
        authCtx.onLogout();
    }

    const goToHome = () => {
        history.push("/")
    }

    const goToServiceRecord = () => {
        history.push({
            pathname: "/serviceRecord",
            state: {
                parameters: ""
            }
        });
    }

    const goToUpdatePassword = () => {
        history.push("/updateUserPassword")
    }

    const aboutMessage = () => {
        setShowAboutModal(true)
    }

    return (
        <Fragment>
            <AboutModal
                show={showAboutModal}
                setShowAboutModal={setShowAboutModal}
            />
            <div style={{ top: props.toolbarHeight }} className="menu">
                <div className="menu__options">
                    <AccountCircleIcon className="user__icon" />
                    <p className="user__name">{authCtx.userName}</p>
                    <hr />
                    <p onClick={goToHome} className="menu__option">Home</p>
                    <p onClick={goToServiceRecord} className="menu__option">Service Record</p>
                    <p onClick={goToUpdatePassword} className="menu__option">Update Password</p>
                    <HorizontalDivider/>
                    <p onClick={aboutMessage} className="menu__option">About</p>
                    <p onClick={updateApp} className="menu__option">Update App</p>
                    <p onClick={onLogoutHandler} className="menu__option">Sign Out</p>
                </div>
                <label htmlFor="click" className="menu__empty__space">
                </label>
            </div>
        </Fragment>
    )
}

export default SideMenu
