import React, { useState, useEffect } from 'react';

const AuthContext = React.createContext({
  userName: "",
  isLoggedIn: false,
  authHeader: "",
  onLogout: () => { },
  onLogin: (token) => { }
});

export const AuthContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authHeader, setAuthHeader] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const userToken = localStorage.getItem('isLoggedIn');
    const userName = localStorage.getItem('userName');
    if (userToken != null && userToken.length > 0) {
      setAuthHeader("Bearer " + userToken);
      setIsLoggedIn(true);
    }
    if (userName != null) {
      setUserName(userName)
    }
  }, []);

  const logoutHandler = () => {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('userName');
    setIsLoggedIn(false);
  };

  const loginHandler = (token, userName) => {
    if (token != null) {
      localStorage.setItem('isLoggedIn', token);
      setAuthHeader("Bearer " + token);
      setIsLoggedIn(true);
    }

    if(userName != null) {
      localStorage.setItem('userName', userName);
      setUserName(userName)
    }
  };

  return (
    <AuthContext.Provider
      value={{
        userName: userName,
        isLoggedIn: isLoggedIn,
        authHeader: authHeader,
        onLogout: logoutHandler,
        onLogin: loginHandler,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
