import React, { Fragment, useState, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Link, useHistory } from 'react-router-dom'
import FullScreenLoader from '../../components/Loaders/FullScreenLoader';
import Toolbar from '../../components/Toolbar/Toolbar'
import DatePicker from '../../components/UI/DatePicker/DatePicker'
import DropDownMenu from '../../components/UI/DropDownMenu/DropDownMenu';
import InputForm from '../../components/UI/InputForm/InputForm';
import AuthContext from '../../store/auth-context';
import API from '../../utils/API';
import './EditServiceRecord.css';

function EditServiceRecord(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [session, setSession] = useState({});
    const location = useLocation()
    const authCtx = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        fetchData(location.state.sessionId)
    }, []);

    const fetchData = async (sessionId) => {
        setIsLoading(true)
        try {
            let config = { headers: { Authorization: authCtx.authHeader, } }
            let response = await API.get(`/v2/services/override/${sessionId}`, config);
            let statusCode = response.status
            if (statusCode == 200) {
                console.log(response.data.message.result.session)
                setSession(response.data.message.result.session)
            }
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
        }
    }

    const backNavigationHandler = async () => {
        history.push({
            pathname: "/serviceRecord",
            state: {
                parameters: location.state.parameters,
                servicePeriodData: location.state.servicePeriodData
            }
        });
    }

    const descriptionChange = (formType, tag, event) => {
        if (formType == "text") {
            session[tag] = event.target.value
        } else if (formType == "date") {
            const date = new Date(event)
            session[tag] = date.toISOString()
        } else if (formType == "dropDownMenu") {
            session[tag] = Number(event.target.value)
        } else if (tag == "exclude_travel" && session.status != "Closed") {
            if (session[tag] == 0) {
                session[tag] = 1
            } else {
                session[tag] = 0
            }
        }
        setSession((prevState) => ({
            ...prevState,
        }));
    }

    const dropDownUpdatedDefault = (newDefault) => {
        session.title = Number(newDefault)
        setSession((prevState) => ({
            ...prevState,
        }));
    }

    const overrideSession = async () => {
        setIsLoading(true)
        try {
            let config = { headers: { Authorization: authCtx.authHeader, } }
            let response = await API.put(`/v2/services/override/${location.state.sessionId}`, session, config);
            let statusCode = response.data.status
            if (statusCode == 200) {
                setIsLoading(false)
                backNavigationHandler()
            } else if (statusCode >= 400 && statusCode < 500) {
            }

        } catch (e) {
        }
    }

    const checked = () => {

    }

    const getStartDay = () => {
        const date = new Date(session.from)
        const dateTo = new Date(session.to)
        const dateNext = null

        var diff = Math.abs(dateTo - date);
        var textTimer = "";
        var errorText = "";
        var dateNextError = false;
        if (dateTo - date > 0) {
            textTimer = msToTime(diff);
        } else {
            textTimer = "0:00";
            errorText = "Error: "
        }

        if (dateNext != null && dateNext - dateTo <= 0) {
            dateNextError = true
            textTimer = "0:00";
            errorText = "Error: "
        }

        if(session.type == "Cancellation") {
            textTimer = msToTime(diff);
            dateNextError = false
            errorText = ""
        }

        var result = ""
        if (dateNextError) {
            result = "Invalid Next Date";
        } else {
            const day = date.toLocaleDateString("en-US", { weekday: 'long' });
            const month = date.toLocaleDateString("en-US", { month: 'long' });
            const dayNumber = date.toLocaleDateString("en-US", { day: 'numeric' });
            const year = date.toLocaleDateString("en-US", { year: 'numeric' });
            const dateFormatted = day + " " + month + " " + dayNumber + ", " + year
            result = errorText + dateFormatted + " for: " + textTimer;
        }
        return result;
    }

    function msToTime(s) {
        var ms = s % 1000;
        s = (s - ms) / 1000;
        var secs = s % 60;
        s = (s - secs) / 60;
        var mins = s % 60;
        var hrs = (s - mins) / 60;

        mins = mins < 10 ? '0' + mins : mins;

        //return hrs + ':' + mins + ':' + secs + '.' + ms;
        return hrs + ':' + mins + ' hours';
    }

    const invalidDate = () => {
        const date = new Date(session.from)
        const dateTo = new Date(session.to)
        const dateNext = null

        if (dateNext != null && dateNext - dateTo <= 0) {
            return true
        }

        if (dateTo - date > 0) {
            return false;
        } else {
            return true;
        }
    }

    const isReadOnly = () => {
        if (session.status != "Closed") {
            return false
        } else {
            return true
        }
    }

    return (
        <Fragment>
            <Toolbar
                titleScreen={"Edit Session"}
                enableBackNavigation={true}
                backNavigationHandler={backNavigationHandler}
            />
            {
                isLoading &&
                <FullScreenLoader />
            }
            {
                !isLoading &&
                <div className='edit__record__wrapper'>
                    <div className='edit__record__container'>
                        <p className="form_header_text">Client:</p>
                        <InputForm
                            type={"Text"}
                            placeholder={"Client"}
                            value={location.state.clientName}
                            onChange={checked}
                            tag="clientid"
                            formType="text"
                            readonly="readonly"
                        />

                        <p className="form_header_text">Start:</p>
                        <DatePicker
                            readOnly={isReadOnly()}
                            value={session.from}
                            onChange={descriptionChange}
                            tag="from"
                            formType="date"
                        />
                        <p className="form_header_text">End:</p>
                        <DatePicker
                            readOnly={isReadOnly()}
                            value={session.to}
                            onChange={descriptionChange}
                            tag="to"
                            formType="date"
                        />
                        <p className="form_header_text">Day and Duration:</p>
                        <InputForm
                            type={"Text"}
                            placeholder={"Day & Duration"}
                            value={getStartDay()}
                            error={invalidDate()}
                            onChange={descriptionChange}
                            tag="Day\Duration"
                            formType="text"
                            readonly="readonly"
                        />
                        <div className='travel__time__container'>
                            <p className="form_header_text">Exclude Travel Time:</p>
                            <input
                                className='radio__container__edit'
                                type="radio"
                                value={session.exclude_travel}
                                onChange={checked}
                                onClick={(e) =>
                                    descriptionChange("", "exclude_travel", e)
                                }
                                checked={session.exclude_travel === 1}
                            />
                        </div>
                        <p className="form_header_text">Activity Mileage:</p>
                        <InputForm
                            type={"Text"}
                            placeholder={"Activity Mileage"}
                            value={session.mileage}
                            onChange={descriptionChange}
                            tag="mileage"
                            formType="text"
                        />
                        <p className="form_header_text">Activity Expenses:</p>
                        <InputForm
                            type={"Text"}
                            placeholder={"Activity Expenses"}
                            value={session.expenses}
                            onChange={descriptionChange}
                            tag="expenses"
                            formType="text"
                        />
                        <p className="form_header_text">Provider Title:</p>
                        <DropDownMenu
                            value={session.title}
                            disabled={isReadOnly()}
                            titles={session.titles}
                            onChange={descriptionChange}
                            dropDownUpdatedDefault={dropDownUpdatedDefault}
                            tag="title"
                            formType="dropDownMenu"
                        />
                        <br />
                        <button
                            className='primary__button'
                            onClick={overrideSession}>
                            Override
                        </button>
                    </div>
                </div>
            }
        </Fragment>
    )
}

export default EditServiceRecord