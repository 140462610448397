import React, { Fragment } from 'react';
import Card from '../../../components/UI/Card/Card'
import './ListItem.css';
import WarningIcon from '@mui/icons-material/Warning';

const ListItem = props => {

    const deleteHandler = (event) => {
        event.preventDefault();
        props.deleteHandler(props.id)
    }

    const submitHandler = (event) => {
        event.preventDefault();
        props.submitHandler(props.id)
    }

    const compareDates = () => {
        const sessionDate = new Date(props.date)
        const today = new Date()
        const result = today - sessionDate
        const dayInMiliseconds = 1000 * 60 * 60 * 24

        if (result > (dayInMiliseconds * 8)) {
            return true
        } else {
            return false
        }
    }

    const getStatus = (status, set) => {
        var statusText = ""
        if (status == 1 && set == 21) {
            statusText = "Status: Session"
        } else if (status == 1 && set == 22) {
            statusText = "Status: Team Meeting"
        } else if (status == 1 && set == 23) {
            statusText = "Status: Clinical Meeting"
        } else if (status == 1 && set == 24) {
            statusText = "Status: Cancellation"
        } else if (status == 1 && set == 25) {
            statusText = "Status: Billing"
        } else if (status == 1 && set == 0) {
            statusText = "Status: Next"
        } else {
            statusText = "Status: Unknown"
        }
        return statusText
    }

    return (
        <Card
            style={{ marginBottom: '1rem' }}>
            <div className="list__item">
                {compareDates() &&
                    <Fragment>
                        <div className='warning__date'><WarningIcon className='warning__icon' /></div>
                        <div className='warning__date__text'>Due</div>
                    </Fragment>
                }
                <div className="card__session__title">{props.title}</div>
                <div className="card__session__date">{props.description}</div>
                <div className="card__session__status">Status: {props.type}</div>
                <button
                    className="button__outline button__orange"
                    onClick={(e) => props.editHandler(e, props.status, props.type, props.id)}
                >
                    Edit
                </button>
                <button
                    className="button__outline button__red"
                    onClick={deleteHandler}>
                    Delete
                </button>
                {
                    props.status == "Open" && props.type != "Next" &&
                    <button
                        className="button__outline button__green"
                        onClick={submitHandler}>
                        Submit
                    </button>
                }
            </div>
        </Card>
    )
}

export default ListItem
