import React, { useContext, useEffect, useState } from 'react';
import FloatingButton from '../../components/Button/FloatingButton'
import Toolbar from '../../components/Toolbar/Toolbar'
import { useHistory } from 'react-router-dom'
import UserContext from '../../store/user-context';
import SessionList from '../Session/ListSession/SessionList';
import CreateSessionModal from '../../components/UI/CreateSessionModal/CreateSessionModal';
import NextSessionModal from '../../components/UI/NextSessionModal/NextSessionModal';
import AuthContext from '../../store/auth-context';
import API from '../../utils/API';

function Home() {
    const history = useHistory();
    const userCtx = useContext(UserContext)
    const authCtx = useContext(AuthContext)
    const [showModal, setShowModal] = useState(false);
    const [showNextModal, setShowNextModal] = useState(false);
    const [sessionNextId, setSessionNextId] = useState();

    useEffect(() => {
        history.replace()
        { userCtx.showCovidForm && history.push("/covidForm"); }
        fetchNotificationData()
    }, []);

    const fetchNotificationData = async () => {
        try {
            let config = { headers: { Authorization: authCtx.authHeader, } }
            let response = await API.get("v2/notifications/provider?filter=unread&limit=25", config);
            if (response.data.message.result.notifications != null &&
                response.data.message.result.notifications.length > 0) {
                userCtx.setUnreadNotifications(true)
            }else {
                userCtx.setUnreadNotifications(false)
            }
        } catch (e) { }
    }

    const sessionCreated = (snid) => {
        history.push({
            pathname: "/editSession",
            state: {
                sessionId: snid,
                sessionCreated: true
            }
        });
    }

    const showNextModalHandler = (show, id) => {
        setShowNextModal(show)
        setSessionNextId(id)
    }

    const refreshSessionList = () => {
        userCtx.setRefreshSessionList(true)
    }

    const goToNotificationScreen = () => {
        history.push("/notificationScreen")
    }

    return (
        <div>
            <Toolbar
                showNotificationIcon={true}
                notificationIconState={userCtx.unreadNotifications}
                goToNotificationScreen={goToNotificationScreen}
                enableBackNavigation={false}
                fixedToolBar={false}
                titleScreen={"Home"}
                showRefreshIcon={true}
                refreshSessionList={refreshSessionList}
            />
            <CreateSessionModal
                show={showModal}
                setShowModal={setShowModal}
                sessionCreated={sessionCreated}
            />
            <NextSessionModal
                sessionId={sessionNextId}
                show={showNextModal}
                setShowNextModal={setShowNextModal}
                sessionCreated={sessionCreated}
            />
            <SessionList
                showNextModal={showNextModalHandler}
            />
            <FloatingButton
                modalState={showModal}
                onClick={setShowModal}
            />
        </div>
    )
}

export default Home
