import { Accordion } from 'react-bootstrap';
import React, { Fragment, useEffect, useContext } from 'react'
import './ServiceRecordItem.css';
import ServiceRecordSessions from '../../components/UI/ServiceRecordSessions/ServiceRecordSessions';
import UserContext from '../../store/user-context';


function ServiceRecordItem(props) {
    const userCtx = useContext(UserContext)
    useEffect(() => {

        if(userCtx.recordServiceItemRef != -1) {
            //console.log(userCtx.recordServiceItemRef)
            const divElement = document.getElementById(userCtx.recordServiceItemRef);
            divElement?.scrollIntoView({ behavior: 'smooth' });
        }

    }, []);

    return (
        <Fragment>
            {
                props.recordList.map((value, index) =>
                    <div className="record__container" key={999 + index}>
                        <Accordion defaultActiveKey="0">

                            <Accordion.Item eventKey={userCtx.recordServiceItemIndex == index ? "0" : "1"}>
                                <Accordion.Header>
                                    <div className='record_item_header'>
                                        <p>{value.first} {value.last}</p>
                                        <p className='last_item'>$ {value.summary.total}</p>
                                    </div>

                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className='item_record_row'>
                                        <p className='record_title'>Client's Name: </p>
                                        <p className='record_description'>{value.first} {value.last}</p>
                                    </div>
                                    {
                                        !(value.ocf18 || value.plan) &&
                                        <div className='item_record_row'>
                                            <p className='record_description_red'>NO Treatment Plan found for this client</p>
                                        </div>
                                    }
                                    <div className='item_record_row'>
                                        <p className='record_title'>Service Period: </p>
                                        <p className='record_description'>{props.servicePeriod}</p>
                                    </div>
                                    <div className='record_title_list'><p>SESSIONS</p></div>
                                    <ServiceRecordSessions
                                        parametersDate={props.parametersDate}
                                        sessions={value.sessions}
                                        servicePeriodData={props.servicePeriod}
                                        clientName={`${value.first} ${value.last}`}
                                        index={index}
                                    />
                                    <br />
                                    <div className='record_title_list'><p>PROVIDER TOTALS</p></div>
                                    <div className='record__session_container'>
                                        <p className='record_session_title_provider'>Rehab Support:</p>
                                        <p className='record_session_description_provider'>{value.totals.rehab_support.hours}Hrs @ ${value.totals.rehab_support.rate}/Hr</p>
                                        <p className='record_session_title_total'>${value.totals.rehab_support.total}</p>
                                    </div>
                                    <div className='record__session_container'>
                                        <p className='record_session_title_provider'>Travel Time:</p>
                                        <p className='record_session_description_provider'>{value.totals.travel_time.hours}Hrs @ ${value.totals.travel_time.rate}/Hr</p>
                                        <p className='record_session_title_total'>${value.totals.travel_time.total}</p>
                                    </div>
                                    <div className='record__session_container'>
                                        <p className='record_session_title_provider'>Travel Mileage:</p>
                                        <p className='record_session_description_provider'>{value.totals.travel_mileage.mileage}Kms @ ${value.totals.travel_mileage.rate}/Km</p>
                                        <p className='record_session_title_total'>${value.totals.travel_mileage.total}</p>
                                    </div>
                                    <div className='record__session_container'>
                                        <p className='record_session_title_provider'>Activity Mileage:</p>
                                        <p className='record_session_description_provider'>{value.totals.activity_mileage.mileage}Kms @ ${value.totals.activity_mileage.rate}/Km</p>
                                        <p className='record_session_title_total'>${value.totals.activity_mileage.total}</p>
                                    </div>
                                    <div className='record__session_container'>
                                        <p className='record_session_title_provider'>Activity Expenses:</p>
                                        <p className='record_session_title_total'>${value.totals.activity_expenses.amount}</p>
                                    </div>
                                    <div className='record__session_container'>
                                        <p className='record_session_title_provider'>Documentation:</p>
                                        <p className='record_session_description_provider'>{value.totals.documentation.hours}Hrs @ ${value.totals.documentation.rate}/Hr</p>
                                        <p className='record_session_title_total'>${value.totals.documentation.total}</p>
                                    </div>
                                    <div className='record__session_container'>
                                        <p className='record_session_title_provider'>Communication:</p>
                                        <p className='record_session_description_provider'>{value.totals.communication.hours}Hrs @ ${value.totals.communication.rate}/Hr</p>
                                        <p className='record_session_title_total'>${value.totals.communication.total}</p>
                                    </div>
                                    <br />
                                    <div className='record_title_list'><p>SUMMARY</p></div>
                                    <div className='record__session_container'>
                                        <p className='record_session_title_summary'>Non-Taxable Subtotal:</p>
                                        <p className='record_session_description_provider'>${value.summary.non_taxable}</p>
                                    </div>
                                    <div className='record__session_container'>
                                        <p className='record_session_title_summary'>Taxable Subtotal:</p>
                                        <p className='record_session_description_provider'>${value.summary.taxable}</p>
                                    </div>
                                    <div className='record__session_container'>
                                        <p className='record_session_title_summary'>HST:</p>
                                        <p className='record_session_description_provider'>${value.summary.hst}</p>
                                    </div>
                                    <div className='record__session_container'>
                                        <p className='record_session_title_summary'>Client Total:</p>
                                        <p className='record_session_description_provider'>${value.summary.total}</p>
                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                )
            }
        </Fragment>
    )
}

export default ServiceRecordItem