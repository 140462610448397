import React, { Fragment } from 'react'
import DateFnsUtils from '@date-io/date-fns';
import "./DatePicker.css"
import {
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

const DatePicker = (props) => {
    return (
        <Fragment>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                    readOnly={props.readOnly}
                    className='date__picker__container'
                    value={props.value || ''}
                    onChange={(e) => props.onChange(props.formType, props.tag, e)}
                />
            </MuiPickersUtilsProvider>
            <div className="date__picker__space" />
        </Fragment>
    )
}

export default DatePicker
