import React, { useEffect } from 'react'
import './CueDropDown.css';
import { Form } from 'react-bootstrap';

function CueDropDown(props) {
    return (
        <div className='cue__container'>
            <span className='text__cue'>{props.title}:</span>
            <div className='cue__select__container'>
            <select
                onChange={(e) => props.onChange(props.tag, props.index, e)}>
                {Array.apply(0, Array(51)).map(function (x, i) {
                    return i == props.defaultValue ? <option selected value={i}>{i}</option> : <option value={i}>{i}</option>;
                })}
            </select>
            </div>
        </div>
    )
}

export default CueDropDown
