import React, { useState, useEffect } from 'react'
import './InputForm.css';

const InputForm = props => {

    const inputStyle = props.error ? "input__form__session__error" : "input__form__session"

    return (
        <div>
            <input
                className={inputStyle}
                type={props.type}
                placeholder={props.placeholder}
                value={props.value || ''}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => props.onChange(props.formType, props.tag, e)}
                ref={props.refValue}
                readonly={props.readonly}
            />
        </div>
    )
}

export default InputForm
