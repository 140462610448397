import React, { useState, useEffect, useContext, Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import FullScreenLoader from '../../components/Loaders/FullScreenLoader';
import Toolbar from '../../components/Toolbar/Toolbar'
import SelectDateModal from '../../components/UI/SelectDateModal/SelectDateModal'
import AuthContext from '../../store/auth-context';
import API from '../../utils/API';
import ServiceRecordItem from './ServiceRecordItem';
import './ServiceRecord.css';


function ServiceRecord(props) {

    const [showDateModal, setShowDateModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordList, setRecordList] = useState([]);
    const [servicePeriod, setServicePeriod] = useState("");
    const [providerTotal, setProviderTotal] = useState("");
    const [parametersDate, setParametersDate] = useState("");
    const location = useLocation()
    const authCtx = useContext(AuthContext);

    useEffect(() => {
        if (location.state.parameters != null && location.state.parameters != '') {
            getServiceRecord(location.state.parameters, location.state.servicePeriodData)
        } else {
            setShowDateModal(true)
        }
    }, []);


    const getServiceRecord = (parameters, servicePeriodData) => {
        setParametersDate(parameters)
        setServicePeriod(servicePeriodData)
        //console.log(servicePeriod)
        setShowDateModal(false)
        fetchData(parameters, servicePeriodData)
    }

    const fetchData = async (parameters, servicePeriodData) => {
        //console.log(parameters)
        setIsLoading(true)
        try {
            let config = { headers: { Authorization: authCtx.authHeader, } }
            let response = await API.get(`/v2/services/record/${parameters}`, config);
            //console.log(response)
            let statusCode = response.data.status
            if (statusCode == 200) {
                if (response.data.message.result.record != null) {
                    //console.log(response)
                    setRecordList(response.data.message.result.record)
                    setProviderTotal(response.data.message.result.total)
                }
            }
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
        }
    }

    const openSelectDate = () => {
        setShowDateModal(true)
    }

    return (
        <div>
            <Toolbar
                enableBackNavigation={false}
                fixedToolBar={false}
                titleScreen={"Service Record"}
                showRefreshIcon={false}
                selectDate={true}
                openSelectDate={openSelectDate}
            />
            <SelectDateModal
                show={showDateModal}
                setShowDateModal={setShowDateModal}
                getServiceRecord={getServiceRecord}
            />
            {
                isLoading &&
                <FullScreenLoader />
            }
            {
                !isLoading &&
                <Fragment>
                    <div className='info__container'>
                        <p className='title_period'>Period: {servicePeriod} </p>
                        <p className='title_period'>Total: ${providerTotal} </p>
                    </div>
                    <ServiceRecordItem
                        parametersDate={parametersDate}
                        recordList={recordList}
                        servicePeriod={servicePeriod}
                    />
                </Fragment>
            }
        </div>
    )
}

export default ServiceRecord