import React, { Fragment } from 'react'
import './SessionPreviewModal.css';
import { Button, Modal } from 'react-bootstrap'
import HorizontalDivider from '../../Divider/HorizontalDivider';
import parse from 'html-react-parser';

function SessionPreviewModal(props) {

    const exit = () => {
        props.setShowPreviewModal(!props.show)
    }

    const formatTime = (date) => {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    const getFormatedDate = (dateValue) => {
        const date = new Date(dateValue)
        const day = date.toLocaleDateString("en-US", { weekday: 'long' });
        const month = date.toLocaleDateString("en-US", { month: 'long' });
        const dayNumber = date.toLocaleDateString("en-US", { day: 'numeric' });
        const year = date.toLocaleDateString("en-US", { year: 'numeric' });
        const dateFormatted = day + " " + month + " " + dayNumber + ", " + year + ", " + formatTime(date)
        return dateFormatted
    }

    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {"Session Note View"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='preview_modal_container'>
                    <div className='header__preview'>Client:</div>
                    <div className='header__description'>{props.sessionDescription.client_last + " " + props.sessionDescription.client_first}</div>
                    <div className='header__preview'>Session Start:</div>
                    <div className='header__description'>{getFormatedDate(props.sessionDescription.from)}</div>
                    <div className='header__preview'>Session End:</div>
                    <div className='header__description'>{getFormatedDate(props.sessionDescription.to)}</div>
                    <div className='header__preview'>Activity Mileage:</div>
                    <div className='header__description'>{props.sessionDescription.mileage}</div>
                    <div className='header__preview'>Activity Expense:</div>
                    <div className='header__description'>{props.sessionDescription.expenses}</div>
                    <div className='header__preview'>Exclude Travel Time :</div>
                    <div className='header__description'>{props.sessionDescription.exclude_travel === 0 ? 'No' : 'Yes'}</div>
                    <HorizontalDivider />
                    {
                        props.sessionRemarks.map((value, index) =>
                            value.type == 1 ?
                                <div className='header__preview__remarks'>{value.heading}</div> :
                                value.content == "" || value.content == null ?
                                    <div> </div> :
                                    <Fragment>
                                        <div className='header__preview'>{value.heading}:</div>
                                        <div className='header__description'>{parse(value.content)}</div>
                                    </Fragment>
                        )
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button onClick={exit} variant="secondary">{"cancel"}</Button> */}
                <Button onClick={exit}>{"Ok"}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SessionPreviewModal