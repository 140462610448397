import React, { useEffect, useState, useContext } from 'react'
import { Button, Modal } from 'react-bootstrap'
import UserContext from '../../../store/user-context';
import './SelectDateModal.css';

function SelectDateModal(props) {

    const userCtx = useContext(UserContext)
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        const date = currentDate;
        updateFromDate(date)
        updateToDate(date)
    }, []);


    const save = () => {
        userCtx.setRecordServiceItemIndex(-1)
        const parameters = `?from=${fromDate}&to=${toDate}`
        const servicePeriod = `${fromDate} - ${toDate}`
        props.getServiceRecord(parameters, servicePeriod)
    }

    const exit = () => {
        props.setShowDateModal(false)
    }

    const updateFromDate = (currentDate) => {
        // month
        const month = currentDate.toLocaleDateString("en-US", { month: '2-digit' });
        // year
        const year = currentDate.toLocaleDateString("en-US", { year: 'numeric' });
        const stringDate = `${year}-${month}-01`

        setFromDate(stringDate)
    }

    const updateToDate = (currentDate) => {
        //const date = new Date();
        // month
        const month = currentDate.toLocaleDateString("en-US", { month: '2-digit' });
        // year
        const year = currentDate.toLocaleDateString("en-US", { year: 'numeric' });
        // last day
        const lastDay = new Date(year, month, 0).getDate();
        const stringDate = `${year}-${month}-${lastDay}`

        setToDate(stringDate)
    }

    const fromChange = (e) => {
        const oldDate = new Date(e.target.value);
        const numberOfDaysToAdd = 1;
        const updatedDate = oldDate.setDate(oldDate.getDate() + numberOfDaysToAdd);
        const newDate = new Date(updatedDate)
        // month
        const month = newDate.toLocaleDateString("en-US", { month: '2-digit' });
        // year
        const year = newDate.toLocaleDateString("en-US", { year: 'numeric' });
        // day
        const day = newDate.toLocaleDateString("en-US", { day: '2-digit' });
        const stringDate = `${year}-${month}-${day}`
        setFromDate(stringDate)
        updateToDate(newDate)
    }

    const toChange = (e) => {
        const today = new Date(e.target.value);
        const numberOfDaysToAdd = 1;
        const date = today.setDate(today.getDate() + numberOfDaysToAdd);
        const newDate = new Date(date)
        // month
        const month = newDate.toLocaleDateString("en-US", { month: '2-digit' });
        // year
        const year = newDate.toLocaleDateString("en-US", { year: 'numeric' });
        // day
        const day = newDate.toLocaleDateString("en-US", { day: '2-digit' });
        const stringDate = `${year}-${month}-${day}`
        setToDate(stringDate)
    }


    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {"Service Record Period"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Select the Month (or date range) for this service record! </p>
                <div className='items__container'>
                    <div className='from__container'><p>From: </p></div>
                    <input className='date__picker' type="date" id="from" name="from" value={fromDate} onChange={(e) => { fromChange(e) }} />
                </div>

                <div className='items__container'>
                    <div className='to_container'><p>To: </p></div>
                    <input className='date__picker' type="date" id="birthday" name="birthday" value={toDate} onChange={(e) => { toChange(e) }} />
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={exit} variant="secondary">{"Exit"}</Button>
                <Button onClick={save}>{"View Service Record"}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SelectDateModal