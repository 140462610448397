import React, { useContext, useState, useEffect, Fragment } from 'react'
import AuthContext from '../../../store/auth-context';
import { useHistory } from 'react-router-dom'
import API from '../../../utils/API';
import ListItem from "./ListItem"
import './SessionList.css';
import FullScreenLoader from '../../../components/Loaders/FullScreenLoader';
import DeleteSessionModal from '../../../components/UI/DeleteSessionModal/DeleteSessionModal';
import SubmitSessionModal from '../../../components/UI/SubmitSessionModal/SubmitSessionModal';
import emptyListIcon from '../../../assets/emptylist.svg'
import UserContext from '../../../store/user-context';

function SessionList(props) {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [sessionList, setSessionList] = useState([]);
    const [sessionDeleteId, setSessionDeleteId] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [sessionSubmitId, setSessionSubmitId] = useState();
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const authCtx = useContext(AuthContext);
    const userCtx = useContext(UserContext)

    useEffect(() => {
        fetchData()
    }, [userCtx.refreshSessionList]);

    const fetchData = async () => {
        setIsLoading(true)
        try {
            let config = { headers: { Authorization: authCtx.authHeader, } }
            let response = await API.get("/v2/sessions/open", config);
            let statusCode = response.data.status
            //console.log("fetchData")
            console.log(response.data)
            if (statusCode == 200) {
                updateSessionList(response.data.message.result.sessions)
                setIsLoading(false)
                userCtx.setRefreshSessionList(false)
            } else if (statusCode >= 400 && statusCode < 500) {
                goToLogin()
            }
        } catch (e) {
            setIsLoading(false)
        }
    }

    const goToLogin = () => {
        setIsLoading(false)
        history.push("/")
        authCtx.onLogout();
    }

    const updateSessionList = (sessionList) => {
        if (sessionList != null && sessionList.length > 0) {
            setSessionList(sessionList)
            orderSessionOpenList(sessionList)
        } else {
            setSessionList([])
        }
    }

    const orderSessionOpenList  = (sessionList) => {
        sessionList.sort(function(a,b){
            return new Date(a.date) - new Date(b.date)
        })
    }

    const formatDate = (date) => {
        var dateObject = new Date(date);
        return dateObject.toLocaleString()
    }

    const editHandler = (event, status, type, id) => {
        event.preventDefault();
        if (status == "Open" && type != "Next") {
            // Open
            history.push({
                pathname: "/editSession",
                state: {
                    sessionId: id,
                    sessionCreated: false
                }
            });
        } else if (status == "Open" && type == "Next") {
            // Next
            props.showNextModal(true, id)
        }
    }

    const submitModal = (id) => {
        setShowSubmitModal(!showSubmitModal)
        setSessionSubmitId(id)
    }

    const submitHandler = async () => {
        //console.log("submit" + sessionSubmitId)
        try {
            let config = { headers: { Authorization: authCtx.authHeader } }
            let response = await API.put(`v2/sessions/note/submit/${sessionSubmitId}`, {}, config);
            let statusCode = response.data.status
            if (statusCode == 200) {
                fetchData()
                setShowSubmitModal(!showSubmitModal)
            }
        } catch (e) {
        }
    }

    const deleteModal = (id) => {
        setShowDeleteModal(!showDeleteModal)
        setSessionDeleteId(id)
    }

    const deleteHandler = async () => {
        try {
            let config = { headers: { Authorization: authCtx.authHeader } }
            let response = await API.delete(`/v2/sessions/note/${sessionDeleteId}`, config);
            let statusCode = response.data.status
            if (statusCode == 200) {
                fetchData()
                setShowDeleteModal(!showDeleteModal)
            }
        } catch (e) {
        }
    }

    return (
        <Fragment>
            {
                isLoading &&
                <FullScreenLoader />
            }
            <DeleteSessionModal
                show={showDeleteModal}
                setShowDeleteModal={setShowDeleteModal}
                deleteHandler={deleteHandler}
            />
            <SubmitSessionModal
                show={showSubmitModal}
                setShowSubmitModal={setShowSubmitModal}
                submitHandler={submitHandler}
            />
            {
                !isLoading &&
                sessionList.length == 0 &&
                <div className='empty__container'>
                    <img src={emptyListIcon} className="empty__icon" />
                    <p>You currently have no open notes</p>
                </div>
            }
            {
                !isLoading &&
                <div className="session__container">
                    <ul className="session__list">
                        {sessionList.map(session => (
                            <div
                                key={session["sessionid"]}
                            >
                                <ListItem
                                    key={session["sessionid"]}
                                    id={session["sessionid"]}
                                    title={session["first"] + " " + session["last"]}
                                    description={formatDate(session["date"])}
                                    date={session["date"]}
                                    status={session["status"]}
                                    set={session["set"]}
                                    type={session["type"]}
                                    editHandler={editHandler}
                                    deleteHandler={deleteModal}
                                    submitHandler={submitModal}
                                />
                            </div>
                        ))}
                    </ul>
                </div>
            }
        </Fragment>
    )
}

export default SessionList
